export const baseUri = process.env.REACT_APP_PUBLIC_PATH;
export const webFolioUri = process.env.REACT_APP_WEBFOLIO_PUBLIC_PATH;
export const publishSecretToken = process.env.REACT_APP_MY_SECRET_TOKEN;
// ADMIN EndPoints
///////////////////////////////////////////////////////////////////////////////////////////
// Publish new content on web foilio site
export const publishNewContentOnSite = `${webFolioUri}/api/revalidate?secret=${publishSecretToken}`;
//////////////////////////////////////////////////////////////////////////////////////////
// Get profile info for current user
export const Get_Current_User_Profile = `${baseUri}/Admin/Profile/info`;
export const Update_Current_User_Profile = `${baseUri}/Admin/Profile/info`;
///////////////////////////////////////////////////////////////////////////////////////////

// About us endpoints
export const Admin_Get_AboutUs_Information = `${baseUri}/Admin/AboutUs`;
export const Admin_Add_AboutUs_Information = `${baseUri}/Admin/AboutUs`;
export const Admin_Update_AboutUs_Information = `${baseUri}/Admin/AboutUs`;

// Empolyee endpoints
export const Admin_Get_List_Of_Employees = `${baseUri}/Admin/AboutUs/OurTeam`;
export const Admin_Add_New_Employee = `${baseUri}/Admin/AboutUs/OurTeam`;
export const Admin_Update_Employee_info = (id) =>
  `${baseUri}/Admin/AboutUs/OurTeam?id=${id}`;
export const Admin_Delete_Employee = (employeeId) =>
  `${baseUri}/Admin/AboutUs/OurTeam?employeeId=${employeeId}`;

///////////////////////////////////////////////////////////////////////////////////////////

// authentiaction endpoints
// export const registerEndpoint
export const loginEndpoint = `${baseUri}/Admin/Account/Login`;

///////////////////////////////////////////////////////////////////////////////////////////

// News endpoints
export const Admin_Get_List_News = `${baseUri}/Admin/News/List`;
export const Admin_Get_Page_List_News = (pageNumber, pageSize) =>
  `${baseUri}/Admin/News/List?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Admin_Get_Specific_News_ById = (id) =>
  `${baseUri}/Admin/News/${id}`;
export const Admin_Add_News = `${baseUri}/Admin/News`;
export const Admin_Update_Specific_News_ById = (id) =>
  `${baseUri}/Admin/News/${id}`;
export const Admin_Delete_Specific_News_ById = (id) =>
  `${baseUri}/Admin/News?newsId=${id}`;
export const Admin_Update_News_order = (id) =>
  `${baseUri}/Admin/News/${id}/order`;

//////////////////////////////////////////////////////////////////////////////////////////

// Projects endpoints
export const Admin_Get_List_Projects = `${baseUri}/Admin/Project/List`;
export const Admin_Get_Page_List_Porjects = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Project/List?PageNumber=${pageNumber}&PageSize=${pageSize}`;

export const Admin_Add_Project = `${baseUri}/Admin/Project`;
export const Admin_Delete_Specific_Project_ById = (id) =>
  `${baseUri}/Admin/Project?id=${id}`;
export const Admin_Get_Specific_Project_ById = (id) =>
  `${baseUri}/Admin/Project/${id}`;
export const Admin_Update_Specific_Project_ById = (id) =>
  `${baseUri}/Admin/Project/${id}`;
export const Admin_Update_Projects_Orders = (id) =>
  `${baseUri}/Admin/Project/${id}/order`;

//////////////////////////////////////////////////////////////////////////////////////////

// contact us endpoints
export const Admin_Get_ContactUs_Information = `${baseUri}/Admin/ContactUs/ContactUs`;
export const Admin_Update_ContactUs_Information = `${baseUri}/Admin/ContactUs`;

/////////////////////////////////////////////////////////////////////////////////////////

//Home page EndPoints
export const Get_Analytics_Info = (limit) =>
  `${baseUri}/Admin/Analytics?limit=${limit}`;

/////////////////////////////////////////////////////////////////////////////////////////

// Main slider and Gallery endpoints
export const Admin_Get_Gallery_Items = `${baseUri}/Admin/Gallery/all`;
export const Admin_Upadte_Slider_Images = `${baseUri}/Admin/Theme/SetSliderImages`;
export const Admin_Upadte_Gallery_Images = `${baseUri}/Admin/Theme/SetGallaryImages`;

/////////////////////////////////////////////////////////////////////////////////////////

// Services category endpoints
export const Admin_Get_All_Services_Gategory = `${baseUri}/Admin/Service`;
export const Admin_Get_Specific_Service_Gategory = (id) =>
  `${baseUri}/Admin/Service/${id}`;
export const Admin_Update_Specific_Service_Category = (id) =>
  `${baseUri}/Admin/Service/${id}`;
export const Admin_Add_New_Service_Category = `${baseUri}/Admin/Service`;
export const Admin_Delete_Service_Gategory = (id) =>
  `${baseUri}/Admin/Service?id=${id}`;
export const Admin_Update_Services_Gategories_Order = (id) =>
  `${baseUri}/Admin/Service/${id}/order`;

////////////////////////////////////////////////////////////////////////////////////////

//Services Endpoints
export const Admin_Get_All_Services = (serviceId = "") =>
  `${baseUri}/Admin/SubServices/All?ServiceId=${serviceId}`;
export const Admin_Delete_Specific_Service = (id) =>
  `${baseUri}/Admin/SubServices?subServiceId=${id}`;
export const Admin_Get_Specific_Service = (id) =>
  `${baseUri}/Admin/SubServices/${id}`;
export const Admin_Update_Specific_Service = (id) =>
  `${baseUri}/Admin/SubServices/${id}`;
export const Admin_Add_New_Service = (id) =>
  `${baseUri}/Admin/SubServices?serviceId=${id}`;
export const Admin_Update_Services_Order = (id) =>
  `${baseUri}/Admin/SubServices/${id}/order`;

////////////////////////////////////////////////////////////////////////////////////////

// Ui & Theming
export const Admin_Get_Global_Config = `${baseUri}/Admin/Theme/GlobalThemeConfiguration`;
export const Admin_Get_Slider_Images = `${baseUri}/Admin/MobileSlider/All`;
export const Admin_Update_Global_Config = `${baseUri}/Admin/Theme/GlobalConfiguration`;
export const Admin_Get_Specific_Theme = (themeId) =>
  `${baseUri}/Admin/Theme/ThemeDetailes?id=${themeId}`;
export const Admin_update_Specific_Theme = (themeId) =>
  `${baseUri}/Admin/Theme/EditTheme?id=${themeId}`;

////////////////////////////////////////////////////////////////////////////////////////

// Files endpoints
export const UploadImage = `${baseUri}/Common/Files/UploadImage`;
export const UploadMultiImages = `${baseUri}/Common/Files/UploadImages`;

////////////////////////////////////////////////////////////////////////////////////////

/// Feedbacks endpoints

export const GetAllFeedbacks = (pageNumber, pageSize) =>
  `${baseUri}/Admin/FeedBack?PageNumber=${pageNumber}&PageSize=${pageSize}`;

///////////////////////////////////////////////////////////////////////////////////////

//// Web Theme endpoints

export const GetWebThemeGlobalInfo = `${baseUri}/web/Admin/Theme/global-configuration`;
export const updateWebThemeGlobalInfo = `${baseUri}/web/Admin/WebLayout`;
export const GetThemeInfo = `${baseUri}/web/admin/global-theme`;
export const UpdteThemeInfo = `${baseUri}/web/admin`;

////////////////////////////////////////////////////////////////////////////////

/// Upload video
export const UploadSiteHeaderVideo = `${baseUri}/web/admin/UploadLargeFile`;
/// upload multi files videos and images and ....

export const UploadMultiImagesAndVideos = `${baseUri}/Common/BaseFile/UploadAnyFile`;

/// upload multi files PDF, Word and ....

export const UploadAttachmentsFiles = `${baseUri}/Common/BaseFile/UploadAttachmentFile`;

/// upload video to temp buffer
export const UploadTempVideoToBuffer = `${baseUri}/Common/BaseFile/uploadTmpVideo`;

////////////////////////////////////////////////////////////////////////////////
// pages setting endpoints

export const Get_All_Pages = `${baseUri}/web/Admin/WebPage/getAllWebPages`;
export const Update_All_Pages = `${baseUri}/web/Admin/WebPage/UpdateList`;
export const Delete_Specific_Page = (pageId) =>
  `${baseUri}/web/Admin/WebPage/${pageId}`;
export const Add_New_Dynamic_Page = `${baseUri}/web/Admin/WebPage/AddDynamicWebPage`;
export const Add_New_Link_Page = `${baseUri}/web/Admin/WebPage/AddLinkWebPage`;
export const Get_Specific_Page_Info = (id) =>
  `${baseUri}/web/Admin/WebPage/getDynamicWebPage?pageId=${id}`;
export const Edit_Specific_Dynamic_Page = (pageId) =>
  `${baseUri}/web/Admin/WebPage/updateDynamicWebPage/${pageId}`;

export const Get_Dynamic_Pages_Main_Title = `${baseUri}/web/Admin/WebPage/parentLink`;
export const Update_Dynamic_Pages_Main_Title = `${baseUri}/web/Admin/WebPage/parentLink`;

////////////////////////////////////////////////////////////////////////

/// SEO Global

export const Get_Global_SEO_info = `${baseUri}/web/Admin/HomeSEO`;
export const Update_Global_SEO_info = `${baseUri}/web/Admin/HomeSEO`;
//////////////////////////////////////////////////////////////////////

/// SEO and Google analytics
export const UnAutherized_Get_GOOGLE_Captcha_Info = `${baseUri}/web/Admin/SEOSetting/withoutAuthentication`;
export const Get_GOOGLE_Captcha_Info = `${baseUri}/web/Admin/SEOSetting`;
export const Update_GOOGLE_Captcha_Info = `${baseUri}/web/Admin/SEOSetting`;

////////////////////////////////////////////////////////////////////////

////// Hero section

/// static version
export const Get_static_version_info = `${baseUri}/web/Admin/HomeStaticVersion`;
export const Update_static_version_info = `${baseUri}/web/Admin/HomeStaticVersion`;

//// Slider version
export const Get_slider_version_all_info = `${baseUri}/web/Admin/HomeSliderVersion/All`;
export const Add_New_slider_info = `${baseUri}/web/Admin/HomeSliderVersion`;
export const get_specific_slider_info = (sliderId) =>
  `${baseUri}/web/Admin/HomeSliderVersion/${sliderId}`;
export const Update_specific_slider_info = (sliderId) =>
  `${baseUri}/web/Admin/HomeSliderVersion/${sliderId}`;
export const Delete_specific_slider_info = (sliderId) =>
  `${baseUri}/web/Admin/HomeSliderVersion/${sliderId}`;
export const Update_specific_slider_Order = (sliderId) =>
  `${baseUri}/web/Admin/HomeSliderVersion/${sliderId}/order`;

////////  Video version

export const Get_Video_version_info = `${baseUri}/web/Admin/HomeVideoVersion`;
export const Update_Video_version_info = `${baseUri}/web/Admin/HomeVideoVersion`;

////////////////////////////////////////////////////////////////////////////////

//// Intro section

export const Get_intro_section_info = `${baseUri}/Admin/IntroSection`;
export const Update_intro_section_info = (id) =>
  `${baseUri}/Admin/IntroSection/${id}`;
///////////////////////////////////////////////////////////////////////////////

///// Features section
export const Get_all_features_items = `${baseUri}/Admin/Feature/List`;
export const Add_New_feature_item = `${baseUri}/Admin/Feature`;
export const Get_specific_feature_item = (featureId) =>
  `${baseUri}/Admin/Feature/${featureId}/Detail`;
export const Update_feature_item_info = (featureId) =>
  `${baseUri}/Admin/Feature/${featureId}`;
export const Delete_specific_Feature = (featureId) =>
  `${baseUri}/Admin/Feature/${featureId}`;
export const Update_Features_Order = (featureId) =>
  `${baseUri}/Admin/Feature/${featureId}/order`;

/////////////////////////////////////////////////////////////////////////////

///// Approach section
export const Get_all_points = `${baseUri}/Admin/ApproachPoint/List`;
export const Add_New_point_item = `${baseUri}/Admin/ApproachPoint`;
export const Get_specific_point = (pointId) =>
  `${baseUri}/Admin/ApproachPoint/${pointId}/Detail`;
export const Update_point_info = (pointId) =>
  `${baseUri}/Admin/ApproachPoint/${pointId}`;
export const Delete_specific_point = (pointId) =>
  `${baseUri}/Admin/ApproachPoint/${pointId}`;
export const Update_points_Orders = (pointId) =>
  `${baseUri}/Admin/ApproachPoint/${pointId}/order`;

//////////////////////////////////////////////////////////////////////////

///// Testimonials section
export const Get_all_testimonials = `${baseUri}/Admin/Testimonial/List`;
export const Add_New_testimonial_item = `${baseUri}/Admin/Testimonial`;
export const Get_specific_testimonial = (testimonialId) =>
  `${baseUri}/Admin/Testimonial/${testimonialId}/Detail`;
export const Update_testimonial_info = (testimonialId) =>
  `${baseUri}/Admin/Testimonial/${testimonialId}`;
export const Delete_specific_testimonial = (testimonialId) =>
  `${baseUri}/Admin/Testimonial/${testimonialId}`;
export const Update_testimonial_Order = (testimonialId) =>
  `${baseUri}/Admin/Testimonial/${testimonialId}/order`;

//////////////////////////////////////////////////////////////////////////

///// Usefull Links section
export const Get_All_Usefull_Links_items = `${baseUri}/Admin/UsefullLink`;
export const Add_New_Usefull_Link = `${baseUri}/Admin/UsefullLink`;
export const Update_Usefull_Link = (linkId) =>
  `${baseUri}/Admin/UsefullLink/${linkId}`;
export const Delete_specific_Usefull_Link = (linkId) =>
  `${baseUri}/Admin/UsefullLink/${linkId}`;

/////////////////////////////////////////////////////////////////////////////

///// Statistics section
export const Get_all_Statistics = `${baseUri}/Admin/Statistic/List`;
export const Add_New_statistic_item = `${baseUri}/Admin/Statistic`;
export const Get_specific_statistic = (statisticId) =>
  `${baseUri}/Admin/Statistic/${statisticId}/Detail`;
export const Update_statistic_info = (statisticId) =>
  `${baseUri}/Admin/Statistic/${statisticId}`;
export const Delete_specific_statistic = (statisticId) =>
  `${baseUri}/Admin/Statistic/${statisticId}`;
export const Update_statistic_Order = (statisticId) =>
  `${baseUri}/Admin/Statistic/${statisticId}/order`;

//////////////////////////////////////////////////////////////////////////

//// ContactUs section
export const Get_Contact_us_section_Info = `${baseUri}/Admin/CommunicateUsSection`;
export const Update_Contact_us_section_Info = `${baseUri}/Admin/CommunicateUsSection`;
/////////////////////////////////////////////////////////////////////////

//// Subscribers
export const Get_Subscribers_List = (pageNumber, pageSize = 10) =>
  `${baseUri}/Admin/Subscriber?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Send_mail_to_all_subscribers = `${baseUri}/Admin/Subscriber`;

//////////////////////////////////////////////////////////////////////////

///// Tutorials section
export const Get_All_Tutorials_items = `${baseUri}/Admin/LearningVideo`;
export const Add_New_Tutorial = `${baseUri}/Admin/LearningVideo`;
export const Update_Tutorial_info = (tutorialId) =>
  `${baseUri}/Admin/LearningVideo/${tutorialId}`;
export const Delete_specific_Tutorial = (tutorialId) =>
  `${baseUri}/Admin/LearningVideo/${tutorialId}`;

/////////////////////////////////////////////////////////////////////////

///// Field Experiments section
export const Get_All_Experiments_items = `${baseUri}/Admin/FieldExperiment`;
export const Add_New_Experiment = `${baseUri}/Admin/FieldExperiment`;
export const Update_Experiment_info = (experimentId) =>
  `${baseUri}/Admin/FieldExperiment/${experimentId}`;
export const Delete_specific_Experiment = (experimentId) =>
  `${baseUri}/Admin/FieldExperiment/${experimentId}`;

/////////////////////////////////////////////////////////////////////////

///// FAQs section
export const Get_all_FAQs = `${baseUri}/Admin/Faq`;
export const Add_New_faq = `${baseUri}/Admin/Faq`;
export const Get_specific_faq = (statisticId) =>
  `${baseUri}/Admin/Statistic/${statisticId}/Detail`;
export const Update_faq_info = (id) => `${baseUri}/Admin/Faq/${id}`;
export const Delete_specific_faq = (id) => `${baseUri}/Admin/Faq/${id}`;
export const Update_faqs_Order = (id) => `${baseUri}/Admin/Faq/${id}/order`;

//////////////////////////////////////////////////////////////////////////

///// Role Management
export const Get_all_Permissions = `${baseUri}/Admin/Permission/All`;
export const Get_all_Roles = `${baseUri}/Admin/Role`;
export const Add_New_Role = `${baseUri}/Admin/Role`;
export const Get_specific_Role = (roleId) => `${baseUri}/Admin/Role/${roleId}`;
export const Update_Role_info = (roleId) => `${baseUri}/Admin/Role/${roleId}`;
export const Delete_specific_Role = (roleId) =>
  `${baseUri}/Admin/Role/${roleId}`;

//////////////////////////////////////////////////////////////////////////

///// Users Management
export const Get_all_Users = (PageNumber, PageSize = 10) =>
  `${baseUri}/Admin/User?PageNumber=${PageNumber}&PageSize=${PageSize}`;
export const Add_New_User = `${baseUri}/Admin/User`;
export const Get_specific_User = (userId) => `${baseUri}/Admin/User/${userId}`;
export const Update_User_info = (userId) => `${baseUri}/Admin/User/${userId}`;
export const Delete_specific_User = (userId) =>
  `${baseUri}/Admin/User/${userId}`;

//////////////////////////////////////////////////////////////////////////

///// OurClients section
export const Get_all_Clients = `${baseUri}/Admin/OurClient/List`;
export const Add_New_Client = `${baseUri}/Admin/OurClient`;
export const Get_specific_Client = (clientId) =>
  `${baseUri}/Admin/OurClient/${clientId}/Detail`;
export const Update_Client_info = (clientId) =>
  `${baseUri}/Admin/OurClient/${clientId}`;
export const Delete_specific_Client = (clientId) =>
  `${baseUri}/Admin/OurClient/${clientId}`;
export const Update_Clients_Order = (clientId) =>
  `${baseUri}/Admin/OurClient/${clientId}/order`;

//////////////////////////////////////////////////////////////////////////

/// E- commerce Endpoints
export const Get_ECommerce_Categories_list_options = `${baseUri}/Admin/Category`;
export const Get_ECommerce_Gategories_List = `${baseUri}/Admin/Category`;
export const Add_New_ECommerce_Gategory_Info = `${baseUri}/Admin/Category`;
export const Update_Specific_ECommerce_Gategory_Info = (categoryId) =>
  `${baseUri}/Admin/Category/${categoryId}`;
export const Delete_Specific_ECommerce_Gategory_Info = (categoryId) =>
  `${baseUri}/Admin/Category/${categoryId}`;
export const MoveCategory = (catId) =>
  `${baseUri}/Admin/Category/${catId}/move`;
export const Update_All_Categories = `${baseUri}/Admin/Category/SubmitFullTree`;

export const Get_ECommerce_Items_List = (filteredParamsPath) =>
  `${baseUri}/Admin/Item?${filteredParamsPath}`;
export const Add_New_ECommerce_Item_Info = `${baseUri}/Admin/Item`;
export const Update_Specific_ECommerce_Item_Info = (ItemId) =>
  `${baseUri}/Admin/Item/${ItemId}`;
export const Delete_Specific_ECommerce_Item = (ItemId) =>
  `${baseUri}/Admin/Item/${ItemId}`;

export const Get_ECommerce_Orders_List = (filteredParamsPath) =>
  `${baseUri}/Admin/Order?${filteredParamsPath}`;
export const Get_ECommerce_Active_Orders_List = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Order/Active?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Get_ECommerce_Previous_Orders_List = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Order/Previous?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Get_ECommerce_Specific_Order_Info = (orderId) =>
  `${baseUri}/Admin/Order/${orderId}`;
export const UpdateSpecificOrderStatusToApproved = `${baseUri}/Admin/Order/approveorders`;
export const AssigneOrderToSpecificDeliveryBoy = (orderId, deliveryboyId) =>
  `${baseUri}/Delivery/Admin/DeliveryBoyOrder/assignOrderDelivery/${orderId}?deliveryboyId=${deliveryboyId}`;
export const Update_ECommerce_Specific_Order_Status_To_Canceled = (orderId) =>
  `${baseUri}/Admin/Order/CancelOrder/${orderId}`;
export const Delete_ECommerce_Specific_Order = (orderId) =>
  `${baseUri}/Admin/Order/${orderId}`;

export const Get_ECommerce_OrderSchedulers_List = (filteredParamsPath) =>
  `${baseUri}/Admin/OrderScheduler?${filteredParamsPath}`;
export const Get_ECommerce_Specific_OrderScheduler_Info = (id) =>
  `${baseUri}/Admin/OrderScheduler/${id}`;
export const Update_ECommerce_Specific_OrderScheduler_Info = (id) =>
  `${baseUri}/Admin/OrderScheduler/${id}`;
export const Delete_ECommerce_Specific_OrderScheduler = (id) =>
  `${baseUri}/Admin/OrderScheduler/${id}`;
export const Confirm_ECommerce_Specific_OrderScheduler = (id) =>
  `${baseUri}/Admin/OrderScheduler/confirmScheduler/${id}`;
export const Delete_ECommerce_Specific_OrderScheduler_Item = (
  schedulerItemId
) => `${baseUri}/Admin/OrderScheduler/deleteSchedulerItem/${schedulerItemId}`;
export const Add_ECommerce_Specific_OrderScheduler_Items = (schedulerId) =>
  `${baseUri}/Admin/OrderScheduler/addScedulerItem/${schedulerId}`;

export const Get_Ecommerce_Sales_Report = (filteredParamsPath) =>
  `${baseUri}/Admin/SellsReport?${filteredParamsPath}`;

export const Get_Dashboard_Ecommerce_Statistics_info = `${baseUri}/Admin/Dashboard`;
export const Get_Dashboard_Ecommerce_Reports_info = `${baseUri}/Admin/Dashboard/Reports`;

export const Get_ECommerce_Brands_List = `${baseUri}/Admin/Brand`;
export const Get_ECommerce_Brands = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Brand?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Add_New_Ecommerce_Brand = `${baseUri}/Admin/Brand`;
export const Update_Specific_Ecommerce_Brand = (brandId) =>
  `${baseUri}/Admin/Brand/${brandId}`;
export const Delete_Specific_Ecommerce_Brand = (brandId) =>
  `${baseUri}/Admin/Brand/${brandId}`;

export const Get_ECommerce_Manufacturers_List = `${baseUri}/Admin/Manufacture`;
export const Get_ECommerce_Manufacturers = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Manufacture?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Add_New_Ecommerce_Manufacturer = `${baseUri}/Admin/Manufacture`;
export const Update_Specific_Ecommerce_Manufacturer = (manufacturerId) =>
  `${baseUri}/Admin/Manufacture/${manufacturerId}`;
export const Delete_Specific_Ecommerce_Manufacturer = (manufacturerId) =>
  `${baseUri}/Admin/Manufacture/${manufacturerId}`;

export const Get_ECommerce_Countries_List = `${baseUri}/Admin/Country`;
export const Get_ECommerce_Countries = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Country?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Add_New_Ecommerce_Country = `${baseUri}/Admin/Country`;
export const Update_Specific_Ecommerce_Country = (countryId) =>
  `${baseUri}/Admin/Country/${countryId}`;
export const Delete_Specific_Ecommerce_Country = (countryId) =>
  `${baseUri}/Admin/Country/${countryId}`;

export const Get_ECommerce_Currencies = (pageNumber = 1, pageSize = 10) =>
  `${baseUri}/Admin/Currency?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Add_New_Ecommerce_Currency = `${baseUri}/Admin/Currency`;
export const Update_Specific_Ecommerce_Currency = (currencyId) =>
  `${baseUri}/Admin/Currency/${currencyId}`;
export const Delete_Specific_Ecommerce_Currency = (currencyId) =>
  `${baseUri}/Admin/Currency/${currencyId}`;

export const Get_ECommerce_Supplier_List = `${baseUri}/Admin/Supplier`;
export const Get_ECommerce_Suppliers = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Supplier?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Add_New_Ecommerce_Supplier = `${baseUri}/Admin/Supplier`;
export const Update_Specific_Ecommerce_Supplier = (supplierId) =>
  `${baseUri}/Admin/Supplier/${supplierId}`;
export const Delete_Specific_Ecommerce_Supplier = (supplierId) =>
  `${baseUri}/Admin/Supplier/${supplierId}`;

export const Get_ECommerce_all_Items_Reviews = (
  pageNumber = 1,
  pageSize = 10,
  itemNameFilter = ""
) =>
  `${baseUri}/Admin/ItemReview?PageNumber=${pageNumber}&PageSize=${pageSize}&ItemName=${itemNameFilter}`;
export const Delete_Specific_Ecommerce_Item_Review = (reviewId) =>
  `${baseUri}/Admin/ItemReview/${reviewId}`;
export const Update_Specific_Ecommerce_Item_Review_Approved = (reviewId) =>
  `${baseUri}/Admin/ItemReview/${reviewId}/Approve`;
export const ADD_Specific_Ecommerce_Item_Offer = (itemId) =>
  `${baseUri}/Admin/Item/${itemId}/Offer`;
export const Delete_Specific_Ecommerce_Item_Offer = (itemId) =>
  `${baseUri}/Admin/Item/${itemId}/Offer`;
export const Update_Specific_Ecommerce_Item_Offer = (offerId) =>
  `${baseUri}/Admin/Offer/${offerId}`;
//////////////////////////////////////////////////////

// Variations endpoints

export const Get_Ecommerce_Item_Variation_List = `${baseUri}/Admin/Variant`;
export const Add_Ecommerce_Item_Variation_Item = `${baseUri}/Admin/Variant`;
export const Update_Ecommerce_Item_Variation_Item_Info = (id) =>
  `${baseUri}/Admin/Variant/${id}`;
export const Delete_Ecommerce_Item_Variation_Item = (id) =>
  `${baseUri}/Admin/Variant/${id}`;

//////////////////////////////////////////////////////

///// item learning videos
export const Get_All_Learning_Videos_For_Specific_Item = (itemId) =>
  `${baseUri}/Admin/LearningVideo/${itemId}/related`;
export const Add_New_Learning_Video_For_Specific_Item = `${baseUri}/Admin/LearningVideo`;
export const Update_Learning_Video_For_Specific_Item = (learningVideotId) =>
  `${baseUri}/Admin/LearningVideo/${learningVideotId}`;
export const Delete_Learning_Video_For_Specific_Item = (learningVideotId) =>
  `${baseUri}/Admin/LearningVideo/${learningVideotId}`;
/////////////////////////////////

export const Get_ECommerce_Coupon_List = (
  pageNumber = 1,
  pageSize = 10,
  text = ""
) =>
  `${baseUri}/Admin/Coupon?PageNumber=${pageNumber}&PageSize=${pageSize}&Text=${text}`;
export const Add_New_Ecommerce_Coupon = `${baseUri}/Admin/Coupon`;
export const Update_Specific_Ecommerce_Coupon = (couponId) =>
  `${baseUri}/Admin/Coupon/${couponId}`;
export const Delete_Specific_Ecommerce_Coupon = (couponId) =>
  `${baseUri}/Admin/Coupon/${couponId}`;

export const Get_ECommerce_Dynamic_Home_Sections = (
  pageNumber = 1,
  pageSize = 10
) =>
  `${baseUri}/Admin/OfferSection/list?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Add_New_Ecommerce_Home_Section = `${baseUri}/Admin/OfferSection`;
export const Get_Specific_Ecommerce_Home_Section = (sectionId) =>
  `${baseUri}/Admin/OfferSection/${sectionId}/Details`;
export const Update_Specific_Ecommerce_Home_Section = (sectionId) =>
  `${baseUri}/Admin/OfferSection/${sectionId}`;
export const Delete_Specific_Ecommerce_Home_Section = (sectionId) =>
  `${baseUri}/Admin/OfferSection/${sectionId}`;
export const Update_Order_for_Specific_Ecommerce_Home_Section = (sectionId) =>
  `${baseUri}/Admin/OfferSection/${sectionId}/order`;

export const Get_SimilarItems_for_Specific_item = (itemId) =>
  `${baseUri}/Admin/Similar/${itemId}/All`;
export const Update_SimilarItems_for_Specific_item = (itemId) =>
  `${baseUri}/Admin/Similar/${itemId}`;

export const Get_Buying_Shipping_Page_Info = `${baseUri}/Admin/BuyingShippingPage`;
export const Update_Buying_Shipping_Page_Info = `${baseUri}/Admin/BuyingShippingPage`;
/////////////////////////////////////////////////////////////////////////

// Publications endpoints
export const Admin_Get_Publications_List = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Publication/List?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Admin_Get_Specific_Publication_ById = (id) =>
  `${baseUri}/Admin/Publication/${id}`;
export const Admin_Add_Publication = `${baseUri}/Admin/Publication`;
export const Admin_Update_Specific_Publication = (id) =>
  `${baseUri}/Admin/Publication/${id}`;
export const Admin_Delete_Specific_Publication = (id) =>
  `${baseUri}/Admin/Publication?publicationId=${id}`;
export const Admin_Update_Publication_order = (id) =>
  `${baseUri}/Admin/Publication/${id}/order`;

//////////////////////////////////////////////////////////////////////////////////////////

// Event Type endpoints
export const Admin_Get_Event_Types_Options_List = `${baseUri}/Admin/EventType`;
export const Admin_Get_Event_types_List = (pageNumber, pageSize) =>
  `${baseUri}/Admin/EventType?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Admin_Add_Event_Type = `${baseUri}/Admin/EventType`;
export const Admin_Update_Specific_Event_Type = (id) =>
  `${baseUri}/Admin/EventType/${id}`;
export const Admin_Delete_Specific_Event_Type = (id) =>
  `${baseUri}/Admin/EventType/${id}`;
export const Admin_Delete_Specific_Event_Action = (eventActionId) =>
  `${baseUri}/Admin/Event/eventAction/${eventActionId}`;
export const Admin_Get_Event_Action_Audience = (
  eventActionId,
  pageNumber,
  pageSize = 10
) =>
  `${baseUri}/Admin/Event/Booking/${eventActionId}?PageNumber=${pageNumber}&PageSize=${pageSize}`;

//////////////////////////////////////////////////////////////////////////////////////////

// Partner endpoints
export const Admin_Get_Partners_List_options = `${baseUri}/Admin/Partner`;
export const Admin_Get_Partners_List = (pageNumber, pageSize) =>
  `${baseUri}/Admin/Partner?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Admin_Add_Partner = `${baseUri}/Admin/Partner`;
export const Admin_Update_Specific_Partner = (id) =>
  `${baseUri}/Admin/Partner/${id}`;
export const Admin_Delete_Specific_Partner = (id) =>
  `${baseUri}/Admin/Partner/${id}`;
//////////////////////////////////////////////////////////////////////////////////////////

// Event endpoints
export const Admin_Get_Event_List = (
  pageNumber,
  pageSize,
  EventTypeId,
  isArchived
) =>
  `${baseUri}/Admin/Event?IsArchived=${isArchived}&PageNumber=${pageNumber}&PageSize=${pageSize}&EventTypeId=${EventTypeId}`;
export const Admin_Add_Event = `${baseUri}/Admin/Event`;
export const Admin_Update_Specific_Event = (id) =>
  `${baseUri}/Admin/Event/${id}`;
export const Admin_Delete_Specific_Event = (id) =>
  `${baseUri}/Admin/Event/${id}`;

///////////////////////////////////////////////////////////////////////////////////////////

// Alert notifications endpoints
export const Admin_Get_Alert_Notifications_List = (pageNumber, pageSize) =>
  `${baseUri}/Admin/AlertNotifications?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Admin_Add_Alert_Notification = `${baseUri}/Admin/AlertNotifications`;
export const Admin_Get_Specific_Alert_Notification = (id) =>
  `${baseUri}/Admin/AlertNotifications/${id}`;
export const Admin_Update_Specific_Alert_Notification = (id) =>
  `${baseUri}/Admin/AlertNotifications/${id}`;
export const Admin_Delete_Specific_Alert_Notification = (id) =>
  `${baseUri}/Admin/AlertNotifications/${id}`;

/////////////////////////////////////////////////////////////////////////////////////////

// PopUp notifications endpoints
export const Admin_Get_Popup_Notifications_List = (pageNumber, pageSize) =>
  `${baseUri}/Admin/PopupNotifications?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Admin_Add_Popup_Notification = `${baseUri}/Admin/PopupNotifications`;
export const Admin_Get_Specific_Popup_Notification = (id) =>
  `${baseUri}/Admin/PopupNotifications/${id}`;
export const Admin_Update_Specific_Popup_Notification = (id) =>
  `${baseUri}/Admin/PopupNotifications/${id}`;
export const Admin_Delete_Specific_Popup_Notification = (id) =>
  `${baseUri}/Admin/PopupNotifications/${id}`;

/////////////////////////////////////////////////////////////////////////////////////////

//Chat endpoints
export const GetChatToken = `${baseUri}/Admin/Account/GetChatToken`;
export const GET_ALL_ROOMS = (uid, PageSize) =>
  `${process.env.REACT_APP_PUBLIC_CHAT_SERVER_PATH}/api/V1/Public/ChatSupport/GetRooms?uid=${uid}&PageSize=${PageSize}`;
export const ROOM_MESSAGES_URL = (uid, messageId, PageSize) =>
  `${process.env.REACT_APP_PUBLIC_CHAT_SERVER_PATH}/api/V1/Public/ChatSupport/GetRoomMessages?uid=${uid}&PageSize=${PageSize}&messageId=${messageId}`;
export const ROOM_UNREAD_MESSAGES_URL = (uid) =>
  `${process.env.REACT_APP_PUBLIC_CHAT_SERVER_PATH}/api/V1/Public/ChatSupport/GetRoomUnreadMessagesCount?uid=${uid}`;
///////////////////////////////////////////////////////////////////////////////////////

/// E- commerce Areas Endpoints
export const Get_ECommerce_Areas_list = `${baseUri}/Admin/Area`;
export const Get_ECommerce_Specific_Area_info = (id) =>
  `${baseUri}/Admin/Area/${id}`;
export const Add_New_ECommerce_Area_Info = `${baseUri}/Admin/Area`;
export const Update_ECommerce_Areas_List = `${baseUri}/Admin/Area/UpdateList`;
export const Update_Specific_ECommerce_Area_Info = (areaId) =>
  `${baseUri}/Admin/Area/${areaId}`;
export const Delete_Specific_ECommerce_Area_Info = (areaId) =>
  `${baseUri}/Admin/Area/${areaId}`;
///////////////////////////////////////////////////////////////////////////////////////

/// E-commerce Delivery Boys Endpoints

export const Get_Delivery_Boys_List = (pageNumber, pageSize = 10) =>
  `${baseUri}/Delivery/Admin/DeliveryBoyAccount?PageNumber=${pageNumber}&PageSize=${pageSize}`;
export const Get_Specific_Delivery_Boy_Info = (id) =>
  `${baseUri}/Delivery/Admin/DeliveryBoyAccount/${id}`;
export const Add_New_Delivery_Boy = `${baseUri}/Delivery/Admin/DeliveryBoyAccount`;
export const Update_Specific_Delivery_Boy_Info = (id) =>
  `${baseUri}/Delivery/Admin/DeliveryBoyAccount/${id}`;
export const Delete_Specific_Delivery_Boy = (id) =>
  `${baseUri}/Delivery/Admin/DeliveryBoyAccount/${id}`;

//////////////////////////////////////////////////////////////////////////////////

// Assets Library endpoints

export const Get_Directories_Tree = `${baseUri}/Admin/LibraryFolder/FoldersTree`;
export const Get_Current_Directory_content = (parentId) =>
  `${baseUri}/Admin/LibraryFolder/${parentId}`;
export const Add_New_Directory = `${baseUri}/Admin/LibraryFolder`;
export const Update_Specific_Directory = (dirId) =>
  `${baseUri}/Admin/LibraryFolder/${dirId}`;
export const Delete_Specific_Directory = (dirId) =>
  `${baseUri}/Admin/LibraryFolder/${dirId}`;
export const Move_Specific_Directory = (dirId) =>
  `${baseUri}/Admin/LibraryFolder/${dirId}/move`;

export const Add_New_File = `${baseUri}/Admin/AssetLibrary`;
export const Update_Specific_File = (fileId) =>
  `${baseUri}/Admin/AssetLibrary/${fileId}`;
export const Delete_Specific_File = (fileId) =>
  `${baseUri}/Admin/AssetLibrary/${fileId}`;
export const Move_Specific_File = (fileId) =>
  `${baseUri}/Admin/AssetLibrary/${fileId}/move`;

//////////////////////////////////////////////////////////

// Page Rate (Reviews)
export const GetPageReviews = (pageNumber, pageSize, pagetype, referenceId) =>
  `${baseUri}/Admin/PageRate?PageNumber=${pageNumber}&PageSize=${pageSize}&pagetype=${pagetype}&ReferenceId=${referenceId}`;
