import axios from "axios";
import Auth from "./authService";
import toast from "react-hot-toast";

axios.interceptors.request.use((config) => {
  config.headers.Authorization = `bearer ${Auth.getJwt()}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    toast.dismiss();

    // temp solution for cors origin when calling publish endpoint
    // (not recommended, you should fix it from next js app)
    if (!error.config.url.includes("/api/revalidate?secret"))
      toast.error(`Error : ${error.response?.data?.message || error}`, {
        hideProgressBar: true,
        position: "top-right",
      });

    // handle status [401, 405] errors
    if (error.response?.status === 401 || error.response?.status === 405) {
      Auth.LogOut();
    }

    return Promise.reject(error);
  }
);

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["Accept-Language"] = "en";
axios.defaults.headers.common["MerchantId"] = "test";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default axios;
